<template>
    <div class="dating-review-post" @scroll="onScroll">
        <div class="header-bar">
            <transition name="slide-down">
                <div v-show="showHiddenHeader" class="title" v-html="'커플 후기 작성하기'"></div>
            </transition>
            <div @click="$stackRouter.pop()" class="left flex-row items-center flex-wrap">
                <i class="material-icons flex-wrap">chevron_left</i>
            </div>
        </div>
        <div class="header" v-html="'커플 후기 작성하기'" />
        <div class="target-cont">
            <img class="profile-image" :src="target.dating_user_photo" />
            <div class="profile-info">
                <div class="name-age">
                    <span>{{ target.dating_user_name }}</span
                    >, {{ target.dating_user_age }}
                </div>
                <div class="agent-date">
                    <div class="box" v-html="`주선자 ${target.agent_name}`"></div>
                    <div class="box m-l-6">{{ target.created_at | formatDate('MM월 DD일') }} 매칭</div>
                </div>
            </div>
        </div>
        <div class="review-cont">
            <div class="title">내용</div>
            <TextareaWithX class="review" placeholder="커플 후기를 작성해 주세요" v-model="review" />
        </div>
        <div class="photo-cont">
            <PhotoInputArea
                :from="`dating-review`"
                :isOption="true"
                @submitPhoto="updatePhoto"
                @clearPhoto="clearPhoto"
            />
        </div>
        <div class="info-box">
            <div class="info">
                <div class="dot" />
                <span>마스크, 모자이크 사진</span>도 괜찮아요!
            </div>
            <div class="info m-t-10">
                <div class="dot" />
                인물 사진 외에, 커플 관련 사진 (ex. 손 잡은 사진, 고백 받은 선물 등), 연인과의 달달한 카톡 내용,
                주선자와의 대화 내용 등을 올리셔도 좋아요!
            </div>
        </div>
        <div class="divider" />
        <div class="agree-cont">
            <div class="checkbox-cont agree-all" @click="agreeAll = !agreeAll">
                <Checkbox v-model="agreeAll" />
                <div>전체 동의</div>
            </div>
            <div class="checkbox-cont agree-phone" @click="agreePhone = !agreePhone">
                <Checkbox v-model="agreePhone" />
                <div>이벤트 당첨 시 연락을 취하기 위해 휴대폰 번호 수집 및 이용에 동의하십니까?</div>
            </div>
            <div class="checkbox-cont agree-marketing" @click="agreeMarketing = !agreeMarketing">
                <Checkbox v-model="agreeMarketing" />
                <div>(선택) 작성된 후기는 바닐라브릿지 홍보 콘텐츠로 사용될 수 있습니다.</div>
            </div>
        </div>
        <div class="bottom">
            <div class="button" :class="{ active: active }" @click="submit">후기 등록</div>
        </div>
    </div>
</template>

<script>
import datingReviewService from '@/services/dating-review'
import PhotoInputArea from '@/components/app/PhotoInputArea'

export default {
    name: 'DatingReviewPostPage',
    components: {
        PhotoInputArea,
    },
    props: ['target'],
    data: () => ({
        showHiddenHeader: false,
        review: '',
        customPhoto: {
            blob: null,
            url: null,
        },
        agreeAll: false,
        agreePhone: false,
        agreeMarketing: false,
    }),
    watch: {
        agreeAll: {
            handler(newVal) {
                this.agreePhone = newVal
                this.agreeMarketing = newVal
            },
        },
        agreePhone: {
            handler(newVal) {
                if (this.agreeMarketing && newVal) this.agreeAll = true
                else if (!this.agreeMarketing && !newVal) this.agreeAll = false
            },
        },
        agreeMarketing: {
            handler(newVal) {
                if (this.agreePhone && newVal) this.agreeAll = true
                else if (!this.agreePhone && !newVal) this.agreeAll = false
            },
        },
    },
    computed: {
        title() {
            if (this.agent) {
                return `주선자 ${this.agent.name}-실제 커플 후기`
            } else {
                return '실제 커플 후기'
            }
        },
        active() {
            return this.agreePhone && this.review.length > 0
        },
    },
    methods: {
        async init() {},
        updatePhoto(photo) {
            this.customPhoto = photo
        },
        clearPhoto() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
        },
        submit() {
            if (this.active) {
                this.$modal
                    .basic({
                        body:
                            '<div class="f-15 m-b-8">커플 후기를 등록합니다.</div><div class="c-text-light f-14">(작성된 후기는 관리자 승인 후 최종 등록되며, 후기 이벤트에 당첨될 경우 별도로 연락드릴 예정입니다.)</div>',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.post()
                        }
                    })
            }
        },
        async post() {
            try {
                this.$loading(true)
                const payload = {
                    dating_id: this.target.id,
                    review: this.review,
                    agree_marketing: this.agreeMarketing,
                }
                console.log(this.customPhoto)
                if (this.customPhoto.blob) payload.photo = this.customPhoto.blob
                await datingReviewService.create(this.preparePayload(payload))
                this.$loading(false)
                this.$toast.success('후기를 작성해 주셔서 감사해요! 예쁜 만남 되시길 바라요!🤗')
                this.$stackRouter.clear()
            } catch (e) {
                this.$loading(false)
                this.$toast.error(e.data)
            }
        },
        preparePayload(payload) {
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                formData.append(key, value)
            })

            return formData
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > 120
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-review-post {
    padding-top: 56px;
    padding-bottom: 136px;
    overflow-y: auto;

    .header-bar {
        position: fixed;
        justify-content: space-between;
        left: 0;
        right: 0;
        z-index: 2;
        border-bottom: none;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            height: 52px;
            top: 0;
            left: 0;
            right: 0;
            background: white;
            border-bottom: 1px solid $grey-03;
            z-index: 1;
            color: black;
            @include f-medium;
        }

        .left {
            z-index: 1;
            position: fixed;
            top: 16px;
            left: 16px;

            .material-icons {
                color: $grey-08;
            }
        }
    }

    .header {
        padding: 0 20px;
        font-size: 24px;
        letter-spacing: -0.4px;
        color: black;
        @include f-medium;
    }

    .target-cont {
        margin: 40px 20px 0 20px;
        background: $grey-01;
        border-radius: 16px;
        padding: 8px 16px;
        display: flex;
        flex-direction: row;

        .profile-image {
            width: 60px;
            height: 60px;
            border-radius: 24px;
            border: solid 1px $grey-02;
        }

        .profile-info {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .name-age {
                color: black;
                font-size: 16px;

                span {
                    @include f-medium;
                    font-weight: 500;
                }
            }

            .agent-date {
                margin-top: 8px;
                display: flex;
                flex-direction: row;
                font-size: 13px;
                color: $grey-09;

                .box {
                    background: white;
                    border-radius: 6px;
                    padding: 2px 8px;
                }
            }
        }
    }

    .review-cont {
        margin-top: 36px;
        padding: 0 20px;

        .title {
            font-size: 14px;
            @include f-medium;
            color: $grey-08;
            font-weight: 500;
        }

        .review {
            margin-top: 10px;
            border: solid 1px $grey-03;
            height: 240px;
        }
    }

    .photo-cont {
        margin-top: 36px;
        padding: 0 20px;

        .title {
            font-size: 14px;
            @include f-medium;
            color: $grey-08;
            font-weight: 500;
        }

        .img-cont {
            margin-top: 10px;
            height: 328px;
            border: solid 1px $grey-03;
            position: relative;

            i {
                height: fit-content;
            }

            .clear {
                position: absolute;
                right: 10px;
                top: 10px;
                border-radius: 50px;
                padding: 4px;
                background: rgba(0, 0, 0, 0.3);
                color: white;
            }

            .photo {
                position: absolute;
                width: 28px;
                height: 28px;
                left: calc(50% - 14px);
                top: calc(50% - 14px);
            }
        }
    }

    .info-box {
        margin: 12px 20px 0 20px;
        padding: 14px;
        border-radius: 10px;
        background: $grey-01;

        .info {
            font-size: 12px;
            color: black;
            display: flex;
            flex-direction: row;

            span {
                color: $purple-primary;
                @include f-medium;
            }

            .dot {
                height: 4px;
                width: 4px;
                border-radius: 2px;
                background: $grey-07;
                margin-right: 8px;
                flex: none;
                margin-top: 7px;
            }
        }
    }

    .divider {
        margin: 36px 20px;
        background: $grey-01;
        height: 1px;
    }

    .agree-cont {
        padding: 0 20px;
        display: flex;
        flex-direction: column;

        .checkbox-cont {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            color: black;

            .checkbox {
                margin-right: 10px;
                margin-top: 2px;
                flex: none;
            }

            &.agree-phone {
                margin-left: 30px;
                margin-top: 18px;
                font-size: 13px;
                color: $grey-08;
            }

            &.agree-marketing {
                margin-left: 30px;
                margin-top: 10px;
                font-size: 13px;
                color: $grey-08;
            }
        }
    }

    .bottom {
        height: 80px;
        width: 100%;
        border-top: 1px solid $grey-02;
        padding: 16px;
        position: absolute;
        bottom: 0;
        background: white;

        .button {
            height: 48px;
            width: 100%;
            line-height: 48px;
            color: $grey-05;
            font-size: 15px;
            border-radius: 8px;
            background: $grey-02;
            text-align: center;

            &.active {
                color: white;
                background: $purple-primary;
            }
        }
    }
}
</style>
